<template>
  <div class="home">
    <UIButton
      href="https://svr.li/k/319"
      target="_blank"
      rel="noopener"
      class="installclientbtn fr"
      tiny
      icon="download"
      v-if="!$store.state.isEmbedded"
    >
      <!-- <UIIcon class="v-mid dib" name="download"></UIIcon> -->
      {{ $t('Install Desktop Client') }}
    </UIButton>
    <h1 class="hello" v-if="user">{{ $t('Welcome to SpringboardVR,') }}&nbsp;{{ user.name }}</h1>
    <template v-if="!$permissions['content-only']">
      <h2 class="monitortitle mb4">{{ $t('Monitor My Stations') }}</h2>
      <div class="locations">
        <div class="location-card" v-for="location in locations" :key="location.id">
          <div class="image">
            <img
              :src="`https://maps.googleapis.com/maps/api/staticmap?center=${urlEncodedAddress(location)}&zoom=15&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative%7Celement:geometry%7Cvisibility:off&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Cvisibility:off&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit%7Cvisibility:off&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&size=400x200&maptype=roadmap&key=${$config.GOOGLE_MAPS_API_KEY}`"
              :alt="location.title"
            />
            <div class="marker"></div>
          </div>
          <div class="main">
            <h1>{{ location.title }}</h1>
            <div class="address">
              <div>{{ location.address }}&nbsp;{{ location.address2 }}</div>
              <div>{{ location.city }}&nbsp;{{ location.state }}&nbsp;{{ location.postalCode }}</div>
            </div>
            <a
              :href="monitorURL(location.id)"
              rel="noopener"
              class="monitor"
            >{{ $t('Monitor My Stations')}}</a>
          </div>
        </div>
      </div>
    </template>
    <h2 class="help-title f1 mb4">{{ $t("We're Here to Help") }}</h2>
    <div class="help f5">
      <div class="help-item">
        <a
          class="title db mb-xs"
          :href="setupGuideLink"
          rel="noopener"
          target="_blank"
        >{{ $t('Setup Guide') }}</a>
        <p>{{ $t("It's your first time here? No problem, we have a guide to get you started with your new location.") }}</p>
      </div>
      <div v-if="!$permissions['enterprise-operator']" class="help-item">
        <a
          class="title db mb-xs"
          href="https://deployreality.com/community/springboard/main/knowledge-base/location-operators"
          rel="noopener"
          target="_blank"
        >{{ $t('Knowledge Base') }}</a>
        <p>{{ $t("Have questions? We have all the answers you need in our knowledge base.") }}</p>
      </div>
      <div v-if="!$permissions['enterprise-operator'] && !$permissions['content-only']" class="help-item">
        <a
          class="title db mb-xs"
          href="https://community.springboardvr.com/"
          rel="noopener"
          target="_blank"
        >{{ $t('SpringboardVR Community') }}</a>
        <p>{{ $t("Join our community of operators, see what others are up to in the industry and get sneak peeks from the SpringboardVR team.") }}</p>
      </div>
      <div class="help-item">
        <a
          v-if="$permissions['education']"
          class="title db mb-xs"
          href="https://springboardvr.canny.io/education-feature-requests"
          rel="noopener"
          target="_blank"
        >{{ $t('Feature Requests') }}</a>
        <a
          v-else
          class="title db mb-xs"
          href="https://features.springboardvr.com/feature-requests"
          rel="noopener"
          target="_blank"
        >{{ $t('Feature Requests') }}</a>
        <p>{{ $t("Have an idea to make SpringboardVR better? Let us know!") }}</p>
      </div>
      <div v-if="!$permissions['enterprise-operator']" class="help-item">
        <a
          v-if="$permissions['education']"
          class="title db mb-xs"
          href="https://springboardvr.canny.io/edu-content-requests"
          rel="noopener"
          target="_blank"
        >{{ $t('Content Requests') }}</a>
        <a
          v-else
          class="title db mb-xs"
          href="https://features.springboardvr.com/commercial-licensing"
          rel="noopener"
          target="_blank"
        >{{ $t('Content Requests') }}</a>
        <p>{{ $t("Let us know which games you want to see added to the SpringboardVR Marketplace.") }}</p>
      </div>
      <div class="help-item">
        <div class="title db mb-xs">{{ $t('Support') }}</div>
        <i18n tag="p" path="Having issues? {contactLink}">
          <template #contactLink>
            <a
              class="link-style"
              href="https://support.springboardvr.com/hc/requests/new"
              rel="noopener"
              target="_blank"
            >{{ $t("Contact our support team.") }}</a>
          </template>
        </i18n>
      </div>
    </div>
    <TOSHomeFooter v-if="!$permissions['enterprise-operator']" />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';
import TOSHomeFooter from '@/core/shared/auth/TOSHomeFooter';

@Component({
  components: {
    UIIcon,
    UIButton,
    TOSHomeFooter,
  },
})
export default class Home extends Vue {
  get user () {
    return this.$store.getters.currentUser;
  }

  get locations () {
    return this.$store.getters['entities/Location/all']();
  }

  get setupGuideLink () {
    if (this.$permissions['content-only']) return 'https://docs.google.com/document/d/1zHscRTQm6fIwQqTsdnUXODs2xxyNEneccdHrYge_riU';
    if (this.$permissions['enterprise-operator']) return 'https://docs.google.com/document/d/16kvExZNxEsmt0QoctXUIhDQGhtKBV1df-TcdO34m3sE/edit#heading=h.ohy2z7recrhj';
    return 'https://svr.li/k/213';
  }

  created () {
    this.$store.commit('setTitle', this.$t('Home'));
  }

  urlEncodedAddress (location) {
    return encodeURIComponent(`${location.address} ${location.address2} ${location.city} ${location.state} ${location.postalCode}`);
  }

  monitorURL (locationId) {
    return `//${window.sbvrenv.MONITOR_URL}/${locationId}/overview`;
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.home {
  @apply --contentContainer;
  padding-top: var(--spacingLg);
  display: flex;
  flex-direction: column;
  height: 100%;

  & .tos-home-footer {
    margin-bottom: -6rem;
  }

  & .homecontent {
    flex: 1 0 auto;
  }

  & .installclientbtn {
    display: none;
    margin-left: auto;

    @media (--tablet) {
      display: inline-block;
    }
  }
  & .hello {
    @apply --f8;
  }
  & .title {
    @apply --f2;
  }
  & a.title {
    color: var(--colorSBRedFlat);
    &:hover {
      color: var(--colorSBRedFlatHover);
    }
  }

  & .monitortitle {
    @apply --f1;
  }

  & .locations {
    display: grid;
    grid-template-columns: repeat(auto-fit, 30rem);
    grid-gap: var(--spacingSm);

    @media (--desktop) {
      grid-gap: var(--spacingMd);
    }

    & .location-card {
      @apply --panelDropShadow;

      background-color: var(--colorWhite);

      & .image {
        position: relative;

        & img {
          width: 30rem;
          height: 15rem;
        }

        & .marker {
          @apply --modalDropShadow;

          position: absolute;
          top: 50%;
          left: 50%;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          background: linear-gradient(45deg, #ec4e36, #ec3679);
        }
      }

      & .main {
        display: grid;
        grid-gap: var(--spacingSm);
        justify-items: center;
        padding: var(--spacingMd);

        & h1 {
          @apply --f3;

          margin: 0;
          color: var(--colorBlack);
          text-align: center;
        }

        & a {
          @apply --buttonSecondary;

          text-decoration: none;
        }

        & .address {
          @apply --f8;

          margin: 0;
          color: var(--colorManatee);
          text-align: center;
        }
      }
    }

    margin-bottom: var(--spacingLg);
  }

  & .help-title {
    margin-top: var(--spacing¡Sm);
  }

  & .help {
    display: grid;
    grid-gap: var(--spacingMd);

    @media (--desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
